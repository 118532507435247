<template>
  <div class="user_info_wrap">
    <div class="left_menu">
      <div class="navbar">
        <div :class="{'nav':true,'isActive':route.path == item.path}"
             v-for="(item,index) in childRoute"
             @click="change(item)"
             :key="index">
          <div class="text"> <img v-if="route.path == item.path"
                 :src="require(`@/static/myinfo/${item.active}.png`)"
                 alt="">
            <img v-else
                 :src="require(`@/static/myinfo/${item.state}.png`)"
                 alt="">{{item.text}}
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view v-if="reload" />
    </div>
  </div>
</template>

<script setup>
import { ref ,reactive} from "@vue/reactivity"
import { nextTick, onMounted } from "@vue/runtime-core"
import { useRoute, useRouter } from "vue-router"
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { message } from 'ant-design-vue'
import { logoutApi } from '@/api/login.js'
import highUrl from '@/utils/highUrl.js'
let route = useRoute()
let router = useRouter()
let reload = ref(true)

let childRoute = reactive([
  { text: '个人信息', path: '/userInfo/info', state: 'icon-个人信息@2x', active: 'icon-个人信息-press@2x' },
  { text: '中考目标', path: '/userInfo/goal', state: 'Target-press', active: 'Target' },
  { text: '学习信息', path: '/userInfo/learningInfo', state: 'icon-个人信息@2x', active: 'icon-个人信息-press@2x' },
  { text: '科目模式', path: '/userInfo/subjectMode', state: 'icon-科目模式@2x', active: 'icon-科目模式active' },
  { text: '修改密码', path: '/userInfo/resetPassword', state: 'icon-修改密码@2x', active: 'icon-修改密码-press@2x' },
  { text: '我的反馈', path: '/userInfo/feedback', state: 'icon-我的反馈-noemal@2x', active: 'icon-我的反馈@2x' },
  { text: '联系我们', path: '/userInfo/contact', state: 'icon-联系我们@2x', active: 'icon-联系我们-press@2x' },
  { text: '用户协议', path: '/userInfo/deal', state: 'icon-用户协议@2x', active: 'icon-用户协议-press@2x' },
  { text: '操作指南', path: '/userInfo/help', state: 'icon-帮助@2x', active: 'icon-帮助-press@2x' },
  { text: '设置题目难度', path: '/userInfo/setDiffculty', state: 'icon-难度@2x', active: 'icon-难度-press@2x' },
  // { text: '版本更新', route: '/login', state: 'icon-版本更新-normal', active: 'icon-版本更新' },
  { text: '退出登录', path: '/logout', state: 'icon-退出登录@2x', active: 'icon-退出登录-press@2x' },
])

onMounted(() => {
  if (JSON.parse(window.localStorage.getItem('userInfo')).edu_grade_id !== 1) {
    childRoute[1].text = '学科目标'
  }
})
const change = (row) => {
  if (row.path == '/logout') {
    logout()
    return
  }
  if (route.path == row.path) {
    reload.value = false
    nextTick(() => {
      reload.value = true
    })
  }
  router.push(row.path)
}

//退出登录
const logout = () => {
  Modal.confirm({
    title: '提示',
    icon: createVNode(ExclamationCircleOutlined),
    okText: '确定',
    cancelText: '取消',
    content: createVNode('div', { style: '' }, '是否确定退出登录?'),
    async onOk () {
      await logoutApi()
      let loginInfo

      let opertionForm = JSON.parse(window.localStorage.getItem('opertionForm'))
      if (opertionForm.rememberPassword) {
        loginInfo = window.localStorage.getItem('loginForm')
      }
      window.sessionStorage.clear()
      window.localStorage.clear()
      message.success('退出登录成功')
      if (loginInfo) {
        window.localStorage.setItem('loginForm', loginInfo)
        window.localStorage.setItem('opertionForm', JSON.stringify(opertionForm))
      }
      router.push('/login?not_auto_login=1')
      // 跳转到高中选择年级页面
      // window.location.href = highUrl + '/changeLevel?level=2&not_auto_login=1'
    },
    onCancel () {
      return
      //console.log('Cancel');
    },
    class: 'test',
  });
}

</script>

<style lang="scss" scoped>
.user_info_wrap {
  display: flex;
  .left_menu {
    .navbar {
      width: 410rem;
      margin-left: 90rem;
      margin-top: 30rem;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 80rem);

      .nav {
        display: flex;
        align-items: center;
        color: white;
        width: 400rem;
        height: 80rem;
        border-radius: 50rem;
        margin-top: 30rem;
        cursor: pointer;
        .text {
          font-weight: 800;
          font-size: 28rem;
          display: flex;
          align-items: center;
          img {
            width: 36rem;
            height: 36rem;
            margin-right: 20rem;
            margin-left: 116rem;
          }
        }
      }
    }
  }
  .main {
    // width: 1160rem;
    width: calc(100% - 614rem);
    height: calc(100vh - 120rem);
    margin-top: 60rem;
    margin-left: 60rem;
    background: white;
    box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
    border-radius: 64rem;
  }
}
.isActive {
  background: #ffffff;
  color: #007af0 !important;
}
</style>